module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio","short_name":"Portfolio","start_url":"/","background_color":"#45443F","theme_color":"#45443F","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"42b220eb3eb438647849f763406e3537"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
